.lowerthird-v1_5 {
  @import "../shared/_defaults";
  @include display-header-small;
  @include origin-flag-tagboard-classic;

  .fullscreen-wrapper {
    background-color: $chromakey-green;
  }

  .display-wrapper {
    top: auto;
    bottom: 0;
    height: 30%;
  }

  .posts {
    top: 6.5vmin;
    left: 5%;
    width: 90%;
    height: calc(92% - 6.5vmin);
  }

  .post {
    font-size: 3vmin;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .5);
    color: $dark-gray;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .has-qr-code {
    background-color: transparent;
  }

  .media-module {
    position: relative;
    width: 20%;
    height: 100%;
    float: left;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .has-qr-code .media-module {
    background-color: rgba($black, .5);
  }

  .blurred-media-module {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 20%;
    height: 100%;
    overflow: hidden;
  }

  .media {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .text-module {
    position: relative;
    width: 100%;
    height: 100%;
    float: right;
    padding: .5em;
    background-color: $white;
    overflow: hidden;
  }

  .has-media .text-module {
    width: 80%;
  }

  .meta-author {
    position: relative;
    font-size: .8em;
    padding-left: 2.75em;
    padding-right: 1em;
    margin-bottom: .5em;
  }

  .no-avatar {
    .meta-author {
      padding-left: 0;
    }
    .avatar {
      display: none;
    }
  }

  .avatar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    margin: auto;
    width: 2.25em;
    height: 2.25em;
    border-radius: 100%;
    box-shadow: 0 0 4px rgba($black, .5);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .user-img {
    visibility: hidden;
    width: 100%;
    height: 100%;
  }

  .author,
  .handle {
    @include ellipsis;
    float: left;
    clear: both;
    max-width: 100%;
    line-height: 2.25;
  }

  .twitter,
  .tiktok {
    .author,
    .handle {
      line-height: 1.3;
    }

    .handle {
      font-size: .8em;
    }
  }

  .reddit .subreddit {
    float: left;
    clear: both;
    max-width: 100%;
    line-height: 1.3;
    @include ellipsis;

    + .handle {
      font-size: .8em;
      line-height: 1.3;
    }
  }

  .text-module-horizontal {
    display: none;
    width: 100%;
    height: 100%;
    float: left;
    padding: .5em;
    padding-right: 1.5em;
    background-color: $white;
    overflow: hidden;
  }

  .has-media .text-module-horizontal {
    width: 80%;
  }

  .meta-author-horizontal {
    position: relative;
    width: auto;
    height: 100%;
    padding-left: 2.75em;
    padding-right: 1em;
  }

  .no-avatar {
    .meta-author-horizontal {
      padding-left: 0;
    }
  }

  .message-horizontal {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .post-text {
      display: block;
      position: absolute;
      left: 0;
      @include vertical-align;
      width: 100%;
      height: auto;
    }
  }

  .post-time {
    display: none;
    font-size: .8em;
    margin-bottom: .5em;
    @include ellipsis;
  }

  .timestamp {
    font-size: .8em;
  }

  .audio-attribution {
    font-size: .6em;
    line-height: 1.5;
    margin-bottom: .5em;
    @include ellipsis;
  }

  .audio-avatar {
    display: none;
    width: 1.5em;
    height: 1.5em;
    margin-left: .15em;
    margin-bottom: -.25em;
    border-radius: 100%;
    background-color: transparent;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-animation: spin 8s linear infinite;
    animation: spin 8s linear infinite;

    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  .audio-info {
    margin-left: .15em;
  }

  .post-title {
    margin-bottom: .5em;
    font-weight: bold;
  }

  .origin-flag {
    top: .5em;
    right: -.3em;
  }

  // QR CODE

  .has-qr-code {
    display: flex;
  }

  .qr-module {
    position: relative;
    width: auto;
    height: 100%;
    margin-right: .5em;
  }

  .square-placeholder {
    width: auto;
    height: 100%;
    visibility: hidden;
  }

  .qr-code {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    canvas,
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }

  // CUSTOMIZATIONS

  .post-color-theme-dark {
    .post {
      color: $white;
    }

    .text-module {
      background-color: rgba($black, .5);
    }

    .has-qr-code .text-module {
      background-color: rgba($black, .75);
    }
  }
}
